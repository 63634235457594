// @ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// @env
import { environment } from 'src/environments/environment';

// @rxjs
import { Observable } from 'rxjs';


// @models
import { IResouceModel, ResourceModel } from 'src/models/resource.model';

@Injectable({
  providedIn: 'root'
})
class ResourcesService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = environment.apiBase;
  }

  public get(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/resources.json`);
  }

  public getLearning(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/learningResources.json`);
  }

  public create(resource: ResourceModel): Observable<IResouceModel> {
    return this.http.post<IResouceModel>(`${this.baseUrl}/resources.json`, resource);
  }

  public createLearning(resource: ResourceModel): Observable<IResouceModel> {
    return this.http.post<IResouceModel>(`${this.baseUrl}/learningResources.json`, resource);
  }

  public update(resource: ResourceModel): Observable<IResouceModel> {
    return this.http.put<IResouceModel>(`${this.baseUrl}/resources/${resource.id}.json`, resource);
  }

  public updateLearning(resource: ResourceModel): Observable<IResouceModel> {
    return this.http.put<IResouceModel>(`${this.baseUrl}/learningResources/${resource.id}.json`, resource);
  }

  public delete(resource: ResourceModel): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/resources/${resource.id}.json`);
  }

  public deleteLearning(resource: ResourceModel): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/learningResources/${resource.id}.json`);
  }
}

export {
  ResourcesService
};
