// @ng
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


// @components
import { AppComponent } from './app.component';
import { ViewRouterComponent } from './router/view-router/view-router.component';

// @modules
import { CoreModule } from 'src/app/modules/core/core.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TdsModule } from 'src/app/modules/tds/tds.module';
import { NotificationsModule } from 'src/app/modules/notifications/notifications.module';

import { AgencyViewModule } from 'src/app/modules/views/agency-view/agency-view.module';
import { LoginViewModule } from 'src/app/modules/views/login-view/login-view.module';
import { NotesViewModule } from 'src/app/modules/views/notes-view/notes-view.module';
import { ProfileViewModule } from 'src/app/modules/views/profile-view/profile-view.module';
import { ResourcesViewModule } from 'src/app/modules/views/resources-view/resources-view.module';
import { SuppliersViewModule } from 'src/app/modules/views/suppliers-view/suppliers-view.module';

import { MobileModule } from 'src/app/modules/mobile/mobile.module';

@NgModule({
  declarations: [
    AppComponent,
    ViewRouterComponent
  ],
  imports: [
    BrowserModule,

    CoreModule,
    SharedModule,
    TdsModule,

    AgencyViewModule,
    LoginViewModule,
    NotesViewModule,
    ProfileViewModule,
    ResourcesViewModule,
    SuppliersViewModule,

    NotificationsModule,
    MobileModule
  ],
  bootstrap: [
    AppComponent
  ]
})
class AppModule { }

export {
  AppModule
};
