interface ISupplierModel {
  address: string;
  consortium: boolean;
  contactsDetails: string;
  contactsName: string;
  description: string;
  id?: string;
  loginID: string;
  loginPassword: string;
  name: string;
  tags: string[];
}

class SupplierModel implements ISupplierModel {
  public address: string = null;
  public consortium: boolean = null;
  public contactsDetails: string = null;
  public contactsName: string = null;
  public description: string = null;
  public id: string = null;
  public loginID: string = null;
  public loginPassword: string = null;
  public name: string = null;
  public tags: string[] = [];

  constructor(id?: string, model?: ISupplierModel) {
    if (model) {
      this.address = model.address;
      this.consortium = model.consortium;
      this.contactsDetails = model.contactsDetails;
      this.contactsName = model.contactsName;
      this.description = model.description;
      this.id = model.id;
      this.loginID = model.loginID;
      this.loginPassword = model.loginPassword;
      this.name = model.name;
      this.tags = model.tags;
    }
    if (id) {
      this.id = id;
    }
  }
}

export {
  ISupplierModel,
  SupplierModel
};
