import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';
import { MobileSuppliersComponent } from './mobile-suppliers/mobile-suppliers.component';
import { FormsModule } from '@angular/forms';

import { TdsModule} from 'src/app/modules/tds/tds.module';

@NgModule({
  declarations: [MobileLoginComponent, MobileSuppliersComponent],
  imports: [
    CommonModule,
    FormsModule,
    TdsModule
  ],
  exports: [MobileLoginComponent, MobileSuppliersComponent]
})
export class MobileModule { }
