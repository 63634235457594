// @enums
import { AdminFunctions } from 'src/enums/adminFunctions.enum';
import { AgencyModes } from 'src/enums/agencyModes.enum';
import { ResourceModes } from 'src/enums/resourceModes.enum';
import { Views } from 'src/enums/views.enum';

interface IRouterState {
  adminFunction: AdminFunctions;
  agencyMode: AgencyModes;
  resourceMode: ResourceModes;
  view: Views;
}

class RouterState implements IRouterState {
  public adminFunction: AdminFunctions = AdminFunctions.ADD_SUPPLIER;
  public agencyMode: AgencyModes = AgencyModes.AGENTS;
  public resourceMode: ResourceModes = ResourceModes.TOOLS;
  public view: Views = Views.LOGIN;

  constructor(view?: Views, model?: IRouterState) {
    if (model) {
      this.adminFunction = model.adminFunction;
      this.agencyMode = model.agencyMode;
      this.resourceMode = model.resourceMode;
      this.view = model.view;
    } else if (view) {
      this.view = view;
    }
  }
}

export {
  RouterState
};
