// @ng
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';


// @services
import { AccountService } from 'src/app/modules/core/services/account.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
class LoginFormComponent implements OnInit {
  public heading: string;

  constructor(private acct: AccountService) {}

  public ngOnInit(): void {
    this.heading = 'Login';
  }

  public submit(form: NgForm) {
    const values = form.value;
    const username: string = values.username.toLowerCase().trim();
    const password: string = values.password.toLowerCase().trim();
    this.acct.login(username, password);
  }
}

export {
  LoginFormComponent
};
