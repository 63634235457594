enum CrudFunctions {
  CREATE,
  READ,
  UPDATE,
  DELETE
}

export {
  CrudFunctions
};
