// @ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// @components
import { LoginViewComponent } from './components/login-view/login-view.component';
import { LoginFormComponent } from './components/login-form/login-form.component';

// @modules
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TdsModule } from 'src/app/modules/tds/tds.module';

@NgModule({
  declarations: [
    LoginViewComponent,
    LoginFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TdsModule
  ],
  exports: [
    LoginViewComponent
  ]
})
class LoginViewModule { }

export {
  LoginViewModule
};
