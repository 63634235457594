// @ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


// @enums
import { CrudFunctions } from 'src/enums/crudFunctions.enum';

// @models
import { SupplierModel } from 'src/models/supplier.model';

// @services
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { SuppliersService } from 'src/app/modules/core/services/suppliers.service';

@Component({
  selector: 'app-modify-supplier',
  templateUrl: './modify-supplier.component.html',
  styleUrls: ['./modify-supplier.component.scss']
})
class ModifySupplierComponent implements OnInit {
  @Input() public crud: CrudFunctions;
  public crudFunctions: typeof CrudFunctions = CrudFunctions;

  @Output() public closePanel: EventEmitter<any> = new EventEmitter<any>();

  public newTag: string = null;

  public supplier: SupplierModel = new SupplierModel();

  constructor(
    private storage: StorageService,
    private suppliers: SuppliersService
  ) { }

  public ngOnInit(): void {
    if (this.crud === CrudFunctions.UPDATE || this.crud === CrudFunctions.DELETE) {
      this.storage.selectedSupplier$.subscribe({
        next: (supplier: SupplierModel) => {
          this.supplier = supplier;
        },
        error: err => console.log(err)
      });
    }
  }

  public addTag(): void {
    if (this.supplier.tags.includes(this.newTag)) {
      this.newTag = null;
      return;
    }
    this.supplier.tags.push(this.newTag);
    this.newTag = null;
  }

  public submit(): void {
    switch (this.crud) {
      case CrudFunctions.CREATE:
        this.suppliers.create(this.supplier).subscribe({
          next: () => {
            this.storage.getSuppliers();
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
        break;
      case CrudFunctions.UPDATE:
        this.suppliers.update(this.supplier).subscribe({
          next: () => {
            this.storage.getSuppliers();
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
        break;
    }
  }

  public confirmDelete(): void {
    this.suppliers.delete(this.supplier).subscribe({
      next: () => {
        this.storage.getSuppliers();
        this.closePanel.emit();
        this.storage.setSelectedSupplier(null);
      },
      error: err => console.log(err)
    });
  }

  public cancelDelete(): void {
    this.closePanel.emit();
  }
}

export {
  ModifySupplierComponent
};
