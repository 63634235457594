enum AdminFunctions {
  ADD_SUPPLIER,
  EDIT_SUPPLIER,
  DELETE_SUPPLIER,
  ADD_RESOURCE,
  EDIT_RESOURCE,
  DELETE_RESOURCE,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
  ADD_NOTE,
  EDIT_NOTE,
  DELETE_NOTE
}

export {
  AdminFunctions
};
