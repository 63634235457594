// @ng
import { Injectable } from '@angular/core';


// @enums
import { AdminFunctions } from 'src/enums/adminFunctions.enum';
import { AgencyModes } from 'src/enums/agencyModes.enum';
import { ResourceModes } from 'src/enums/resourceModes.enum';
import { Views } from 'src/enums/views.enum';

// @models
import { RouterState } from 'src/models/routerState.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
class RouterService {
  private currentState: RouterState;

  constructor(private storage: StorageService) {
    this.currentState = new RouterState();
  }

  public setView(view: Views): void {
    this.currentState.view = view;
    this.storage.setSelectedNote(null);
    this.storage.setSelectedResource(null);
    this.storage.setSelectedSupplier(null);
    this.storage.setSelectedUser(null);
  }

  public setAgencyMode(mode: AgencyModes): void {
    this.currentState.agencyMode = mode;
  }

  public setResourceMode(mode: ResourceModes): void {
    this.currentState.resourceMode = mode;
  }

  public setAdminFunction(f: AdminFunctions) {
    this.currentState.adminFunction = f;
  }

  public getCurrentState(): RouterState {
    return this.currentState;
  }

  public openInNewTab(url: string): void {
    window.open(url);
  }

  public sendEmail(address: string) {
    window.location.href = `mailto:${address}`;
  }
}

export {
  RouterService
};
