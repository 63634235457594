// @ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// @env
import { environment } from 'src/environments/environment';

// @rxjs
import { Observable } from 'rxjs';
import { SupplierModel, ISupplierModel } from 'src/models/supplier.model';

@Injectable({
  providedIn: 'root'
})
class SuppliersService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBase;
  }

  public get(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/suppliers.json`);
  }

  public create(supplier: SupplierModel): Observable<ISupplierModel> {
    return this.http.post<ISupplierModel>(`${this.baseUrl}/suppliers.json`, supplier);
  }

  public update(supplier: SupplierModel): Observable<ISupplierModel> {
    return this.http.put<ISupplierModel>(`${this.baseUrl}/suppliers/${supplier.id}.json`, supplier);
  }

  public delete(supplier: SupplierModel): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/suppliers/${supplier.id}.json`);
  }
}

export {
  SuppliersService
};
