// @ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


// @enums
import { CrudFunctions } from 'src/enums/crudFunctions.enum';
import { ResourceModel } from 'src/models/resource.model';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { ResourcesService } from 'src/app/modules/core/services/resources.service';
import { ResourceTypes } from 'src/enums/resourceTypes.enum';

@Component({
  selector: 'app-modify-resource',
  templateUrl: './modify-resource.component.html',
  styleUrls: ['./modify-resource.component.scss']
})
class ModifyResourceComponent implements OnInit {
  @Input() public crud: CrudFunctions;
  @Output() public closePanel: EventEmitter<any> = new EventEmitter<any>();

  public crudFunctions: typeof CrudFunctions = CrudFunctions;
  public resourceTypes: typeof ResourceTypes = ResourceTypes;

  public resource: ResourceModel = new ResourceModel(ResourceTypes.LEARNING);

  constructor(
    private resources: ResourcesService,
    private storage: StorageService
  ) { }

  ngOnInit() {
    if (this.crud === CrudFunctions.UPDATE || this.crud === CrudFunctions.DELETE) {
      this.storage.selectedResource$.subscribe({
        next: (resource: ResourceModel) => {
          this.resource = resource;
        },
        error: err => console.log(err)
      });
    }
  }

  public submit(): void {
    console.log(this.resource);
    if (this.crud === CrudFunctions.CREATE) {
      if (this.resource.type === ResourceTypes.LEARNING) {
        this.resources.createLearning(this.resource).subscribe({
          next: () => {
            this.storage.getResources();
            this.storage.setSelectedResource(null);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
      } else {
        this.resources.create(this.resource).subscribe({
          next: () => {
            this.storage.getResources();
            this.storage.setSelectedResource(null);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
      }
    } else {
      if (this.resource.type === ResourceTypes.LEARNING) {
        this.resources.updateLearning(this.resource).subscribe({
          next: () => {
            this.storage.getResources();
            this.storage.setSelectedResource(null);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
      } else {
        this.resources.update(this.resource).subscribe({
          next: () => {
            this.storage.getResources();
            this.storage.setSelectedResource(null);
            this.closePanel.emit();
          }
        });
      }
    }
  }

  public confirmDelete(): void {
    if (this.resource.type === ResourceTypes.LEARNING) {
      this.resources.deleteLearning(this.resource).subscribe({
        next: () => {
          this.storage.getResources();
          this.closePanel.emit();
          this.storage.setSelectedResource(null);
        },
        error: err => console.log(err)
      });
    } else {
      this.resources.delete(this.resource).subscribe({
        next: () => {
          this.storage.getResources();
          this.closePanel.emit();
          this.storage.setSelectedResource(null);
        },
        error: err => console.log(err)
      });
    }
  }

  public cancelDelete(): void {
    this.closePanel.emit();
  }
}

export {
  ModifyResourceComponent
};
