import { INoteModel, NoteModel } from './note.model';

interface IUserModel {
  address1?: string;
  address2?: string;
  author?: string;
  bio?: string;
  email?: string;
  facebook?: string;
  favorites?: string[];
  id?: string;
  instagram?: string;
  linkedIn?: string;
  name?: string;
  notes?: INoteModel[];
  phone?: string;
  quote?: string;
  twitter?: string;
  url?: string;
  username?: string;
  youTube?: string;
}

class UserModel implements IUserModel {
  public address1: string = null;
  public address2: string = null;
  public author: string = null;
  public bio: string = null;
  public email: string = null;
  public facebook: string = null;
  public favorites: string[] = null;
  public id: string = null;
  public instagram: string = null;
  public linkedIn: string = null;
  public name: string = null;
  public notes: NoteModel[] = null;
  public phone: string = null;
  public quote: string = null;
  public twitter: string = null;
  public url: string = null;
  public username: string = null;
  public youTube: string = null;

  constructor(model?: IUserModel) {
    if (model) {
      if (model.address1) {
        this.address1 = model.address1;
      }

      if (model.address2) {
        this.address2 = model.address2;
      }

      if (model.author) {
        this.author = model.author;
      }

      if (model.bio) {
        this.bio = model.bio;
      }

      if (model.email) {
        this.email = model.email;
      }

      if (model.facebook) {
        this.facebook = model.facebook;
      }

      if (model.favorites) {
        this.favorites = model.favorites;
      }

      if (model.id) {
        this.id = model.id;
      }

      if (model.instagram) {
        this.instagram = model.instagram;
      }

      if (model.linkedIn) {
        this.linkedIn = model.linkedIn;
      }

      if (model.name) {
        this.name = model.name;
      }

      if (model.notes) {
        this.notes = model.notes;
      }

      if (model.phone) {
        this.phone = model.phone;
      }

      if (model.quote) {
        this.quote = model.quote;
      }

      if (model.twitter) {
        this.twitter = model.twitter;
      }

      if (model.url) {
        this.url = model.url;
      }

      if (model.username) {
        this.username = model.username;
      }

      if (model.youTube) {
        this.youTube = model.youTube;
      }
    }
  }
}

export {
  IUserModel,
  UserModel
};
