// @ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// @components
import { AgencyViewComponent } from './components/agency-view/agency-view.component';

// @modules
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TdsModule } from 'src/app/modules/tds/tds.module';

@NgModule({
  declarations: [
    AgencyViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TdsModule
  ],
  exports: [
    AgencyViewComponent
  ]
})
class AgencyViewModule { }

export {
  AgencyViewModule
};
