// @ng
import { Component, OnInit } from '@angular/core';


// @logging
import { LogEntryTypes, LogEntry, LoggerService } from '../../namespaces/logger.namespace';

@Component({
  selector: 'tds-logger-view',
  templateUrl: './logger-view.component.html',
  styleUrls: ['./logger-view.component.scss']
})
class LoggerViewComponent implements OnInit {
  private context: string;
  public entryTypes: typeof LogEntryTypes = LogEntryTypes;
  public log: LogEntry[];

  constructor(private logger: LoggerService) { }

  ngOnInit() {
    this.context = this.constructor.name;
    this.log = this.logger.publish();
    this.logger.record(new LogEntry({
      type: LogEntryTypes.TEXT,
      text: 'Initialized',
      context: this.context
    }));
  }
}

export {
  LoggerViewComponent
};
