// @ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


// @components
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { HeaderComponent } from './components/header/header.component';
import { ModifyNoteComponent } from './components/modify-note/modify-note.component';
import { ModifyResourceComponent } from './components/modify-resource/modify-resource.component';
import { ModifySupplierComponent } from './components/modify-supplier/modify-supplier.component';
import { ModifyUserComponent } from './components/modify-user/modify-user.component';

// @modules
import { TdsModule } from 'src/app/modules/tds/tds.module';

@NgModule({
  declarations: [
    AdminPanelComponent,
    HeaderComponent,
    ModifyNoteComponent,
    ModifyResourceComponent,
    ModifySupplierComponent,
    ModifyUserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TdsModule
  ],
  exports: [
    HeaderComponent,
    AdminPanelComponent
  ]
})
class SharedModule { }

export {
  SharedModule
};
