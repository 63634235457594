// @models
import { LogEntry } from './logEntry.model';

class Log {
  private entries: LogEntry[];

  constructor() {
    this.entries = [];
  }

  public add(entry: LogEntry): void {
    this.entries.push(entry);
  }

  public get(): LogEntry[] {
    return this.entries;
  }
}

export {
  Log
};
