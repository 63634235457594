class Notification {
  public message: string = '';
  public time: number = 0;
  public icon?: string = '';
  public visibility: string = 'invisible';
}

export {
  Notification
};
