// @ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


// @enums
import { CrudFunctions } from 'src/enums/crudFunctions.enum';

// @models
import { UserModel } from 'src/models/user.model';
import { NoteModel } from 'src/models/note.model';

// @services
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { UsersService } from 'src/app/modules/core/services/users.service';
import { NotificationsService } from 'src/app/modules/notifications/notifications.namespace';

@Component({
  selector: 'app-modify-note',
  templateUrl: './modify-note.component.html',
  styleUrls: ['./modify-note.component.scss']
})
class ModifyNoteComponent implements OnInit {
  @Input() public crud: CrudFunctions;
  public crudFunctions: typeof CrudFunctions = CrudFunctions;

  @Output() public closePanel: EventEmitter<any> = new EventEmitter<any>();

  public currentUser: UserModel;
  public note: NoteModel = new NoteModel();

  constructor(
    private storage: StorageService,
    private users: UsersService,
    private messenger: NotificationsService
  ) { }

  public ngOnInit(): void {
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
        if (this.crud === CrudFunctions.UPDATE || this.crud === CrudFunctions.DELETE) {
          this.storage.selectedNote$.subscribe({
            next: (note: NoteModel) => {
              if (note) {
                this.note = this.currentUser.notes.find(x => x.title === note.title);
              }
            },
            error: err => console.log(err)
          });
        }
      },
      error: err => console.log(err)
    });
  }

  public submit(): void {
    const user = { ...this.currentUser };
    switch (this.crud) {
      case CrudFunctions.CREATE:
        user.notes.push(this.note);
        this.users.update(user).subscribe({
          next: () => {
            this.storage.getUsers();
            this.storage.setSelectedNote(null);
            this.messenger.sendMessage('Note was successfully created.', 2000);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
        break;
      case CrudFunctions.UPDATE:
        this.users.update(user).subscribe({
          next: res => {
            this.storage.getUsers();
            this.storage.setSelectedNote(null);
            this.closePanel.emit();
            console.log(res);
          },
          error: err => console.log(err)
        });
        break;
    }
  }

  public confirmDelete(): void {
      const index = this.currentUser.notes.lastIndexOf(this.note);
      console.log('in delete', this.note);
      console.log(index);
      this.currentUser.notes.splice(this.currentUser.notes.lastIndexOf(this.note), 1);
      this.users.update(this.currentUser).subscribe({
        next: () => {
          this.storage.getUsers();
          this.storage.setCurrentUser(this.currentUser);
          this.storage.setSelectedNote(null);
          this.note = null;
          this.closePanel.emit();
        },
        error: err => console.log(err)
      });
    }

  public cancelDelete(): void {
    this.closePanel.emit();
  }
}

export {
  ModifyNoteComponent
};
