import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { NoteModel } from 'src/models/note.model';
import { AdminFunctions } from 'src/enums/adminFunctions.enum';
import { RouterService } from 'src/app/modules/core/services/router.service';
import { UserModel } from 'src/models/user.model';
import { Views } from 'src/enums/views.enum';

@Component({
  selector: 'app-notes-view',
  templateUrl: './notes-view.component.html',
  styleUrls: ['./notes-view.component.scss']
})
export class NotesViewComponent implements OnInit {
  public notes: NoteModel[] = null;
  public selectedNote: NoteModel = null;
  public showAdminPanelOuter: boolean = false;
  public showAdminPanelInner: boolean = false;

  public adminFunctions: typeof AdminFunctions = AdminFunctions;

  constructor(
    private storage: StorageService,
    private router: RouterService
  ) { }

  ngOnInit() {
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.notes = user.notes;
      },
      error: err => console.log(err)
    });
    this.storage.selectedNote$.subscribe({
      next: (note: NoteModel) => {
        this.selectedNote = note;
      },
      error: err => console.log(err)
    });
  }

  public selectNote(note: NoteModel): void {
    this.storage.setSelectedNote(note);
  }

  public openAdminPanelOuter(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelOuter = true;

  }

  public openAdminPanelInner(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelInner = true;
  }

  public closeAdminPanelOuter() {
    this.showAdminPanelOuter = false;
  }

  public closeAdminPanelInner() {
    this.showAdminPanelInner = false;
  }
}
