// @ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//rich text
import { CovalentTextEditorModule } from '@covalent/text-editor';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// @fa
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSun,
  faMoon,
  faHeart,
  faEdit,
  faPlus,
  faTimes,
  faGraduationCap,
  faTools,
  faEnvelope,
  faCaretRight,
  faPlay,
  faArrowRight,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
// import { } from '@fortawesome/free-regular-svg-icons';

// @components
import { ThemeProviderComponent } from './components/theme-provider/theme-provider.component';
import { LoggerViewComponent } from './components/logger-view/logger-view.component';

@NgModule({
  declarations: [
    ThemeProviderComponent,
    LoggerViewComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    CovalentTextEditorModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  exports: [
    ThemeProviderComponent,
    FontAwesomeModule,
    LoggerViewComponent,
    CovalentTextEditorModule,
    FormsModule,
    FroalaEditorModule,
    FroalaViewModule
  ]
})
class TdsModule {
  constructor() {
    library.add(
      faSun,
      faMoon,
      faHeart,
      faEdit,
      faPlus,
      faTimes,
      faGraduationCap,
      faTools,
      faEnvelope,
      faFacebook,
      faTwitter,
      faInstagram,
      faCaretRight,
      faPlay,
      faLinkedin,
      faYoutube,
      faArrowRight,
      faArrowLeft
    );
  }
 }

export {
  TdsModule
};
