interface INoteModel {
  body: string;
  noteID: string;
  title: string;
}

class NoteModel {
  public body: string = null;
  public noteID: string = null;
  public title: string = null;

  constructor(model?: INoteModel) {
    if (model) {
      this.body = model.body;
      this.noteID = model.noteID;
      this.title = model.title;
    }
  }
}

export {
  INoteModel,
  NoteModel
};
