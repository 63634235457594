// @components
import { ToastComponent } from './components/toast/toast.component';

// @services
import { NotificationsService } from './services/notifications.service';

export {
  NotificationsService,
  ToastComponent
};
