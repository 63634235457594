import { Component, OnInit } from '@angular/core';
import { ResourceModel } from 'src/models/resource.model';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { ResourceTypes } from 'src/enums/resourceTypes.enum';
import { ResourceModes } from 'src/enums/resourceModes.enum';
import { RouterService } from 'src/app/modules/core/services/router.service';
import { AgencyModes } from 'src/enums/agencyModes.enum';
import { AdminFunctions } from 'src/enums/adminFunctions.enum';

@Component({
  selector: 'app-resources-view',
  templateUrl: './resources-view.component.html',
  styleUrls: ['./resources-view.component.scss']
})
export class ResourcesViewComponent implements OnInit {
  public tools: ResourceModel[] = [];
  public learning: ResourceModel[] = [];
  public resourceModes: typeof ResourceModes = ResourceModes;
  public agencyModes: typeof AgencyModes = AgencyModes;
  public selectedResource: ResourceModel = null;

  public showAdminPanelOuter: boolean = false;
  public showAdminPanelInner: boolean = false;

  public adminFunctions: typeof AdminFunctions = AdminFunctions;

  constructor(private storage: StorageService, public router: RouterService) { }

  ngOnInit() {
    this.storage.resources$.subscribe({
      next: (resources: ResourceModel[]) => {
        console.log('in resources next');
        resources.forEach(r => {
          if (r.type === ResourceTypes.TOOLS) {
            this.tools.push(r);
          } else {
            this.learning.push(r);
          }
        });
      },
      error: err => console.log(err)
    });
    this.storage.selectedResource$.subscribe({
      next: (resource: ResourceModel) => {
        this.selectedResource = resource;
      },
      error: err => console.log(err)
    });
  }

  public setMode(mode: ResourceModes): void {
    this.router.setResourceMode(mode);
  }

  public selectResource(resource: ResourceModel): void {
    this.storage.setSelectedResource(resource);
  }

  goToSite(url: string): void {
    this.router.openInNewTab(url);
  }

  public openAdminPanelOuter(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelOuter = true;

  }

  public openAdminPanelInner(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelInner = true;
  }

  public closeAdminPanelOuter() {
    this.showAdminPanelOuter = false;
  }

  public closeAdminPanelInner() {
    this.showAdminPanelInner = false;
  }
}
