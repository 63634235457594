import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
class UtilitiesService {
  constructor() { }

  public isNullOrEmpty(s: string): boolean {
    return s === '' || s === null || s === undefined;
  }

  public sort(array: any[], prop: string) {
    return array.sort(
      (a, b) => (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() > a[prop].toLowerCase()) ? -1 : 0));
  }

  public generateID(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
}

export {
  UtilitiesService
};
