// @ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


// @enums
import { AdminFunctions } from 'src/enums/adminFunctions.enum';

// @services
import { RouterService } from 'src/app/modules/core/services/router.service';
import { CrudFunctions } from 'src/enums/crudFunctions.enum';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  @Input()
  public show: boolean = false;

  @Output()
  public panelClosed: EventEmitter<any> = new EventEmitter<any>();

  public adminFunctions: typeof AdminFunctions = AdminFunctions;
  public crudFunctions: typeof CrudFunctions = CrudFunctions;

  constructor(public router: RouterService) { }

  ngOnInit() {
  }

  public close() {
    this.panelClosed.emit();
  }
}
