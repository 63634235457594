// @ng
import { Component, OnInit } from '@angular/core';


// @enums
import { AgencyModes } from 'src/enums/agencyModes.enum';
import { AdminFunctions } from 'src/enums/adminFunctions.enum';

// @models
import { UserModel } from 'src/models/user.model';

// @services
import { RouterService } from 'src/app/modules/core/services/router.service';
import { StorageService } from 'src/app/modules/core/services/storage.service';

@Component({
  selector: 'app-agency-view',
  templateUrl: './agency-view.component.html',
  styleUrls: ['./agency-view.component.scss']
})
class AgencyViewComponent implements OnInit {
  public agents: UserModel[] = [];
  public selectedAgent: UserModel = null;
  public currentUser: UserModel = null;
  public agencyModes: typeof AgencyModes = AgencyModes;
  public adminFunctions: typeof AdminFunctions = AdminFunctions;

  public showAdminPanelInner: boolean = false;
  public showAdminPanelOuter: boolean = false;

  constructor(
    public router: RouterService,
    private storage: StorageService
  ) {}

  public ngOnInit(): void {
    this.storage.users$.subscribe({
      next: (users: UserModel[]) => {
        this.agents = users;
      }
    });
    this.storage.selectedUser$.subscribe({
      next: (user: UserModel) => {
        this.selectedAgent = user;
      },
      error: err => console.log(err)
    });
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
      },
      error: err => console.log(err)
    });
  }

  public selectAgent(agent: UserModel): void {
    this.storage.setSelectedUser(agent);
  }

  public goTo(url: string) {
    this.router.openInNewTab(url);
  }

  public sendEmail(address: string) {
    this.router.sendEmail(address);
  }

  public openAdminPanelInner(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelInner = true;
  }

  public openAdminPanelOuter(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelOuter = true;
  }

  public closeAdminPanelInner() {
    this.showAdminPanelInner = false;
  }

  public closeAdminPanelOuter() {
    this.showAdminPanelOuter = false;
  }
}

export {
  AgencyViewComponent
};
