enum LogEntryTypes {
  ERROR,
  HTTP_ERROR,
  HTTP_REQUEST,
  OBJECT,
  TEXT
}

export {
  LogEntryTypes
};
