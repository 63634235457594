// @ng
import { Injectable } from '@angular/core';

// @models
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
class NotificationsService {
  public notification: Notification = new Notification();
  constructor() {}

  public sendMessage(message: string, time: number, icon?: string): void {
    this.notification.message = message;
    this.notification.time = time;

    if (icon) {
      this.notification.icon = icon;
    }

    if (this.notification.time > 0) {
      this.show();

      setTimeout(this.hide, this.notification.time);
    }
  }

  private show(): void {
    console.log('show toast');
    this.notification.visibility = 'visible';
  }

  private hide = (): void => {
    console.log('hide toast');
    this.notification.visibility = 'invisible';
  }
}

export {
  NotificationsService
};
