// @ng
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// @rxjs
import { BehaviorSubject, Observable } from 'rxjs';


// @enums
import { ResourceTypes } from 'src/enums/resourceTypes.enum';

// @models
import { NoteModel } from 'src/models/note.model';
import { ResourceModel } from 'src/models/resource.model';
import { SupplierModel } from 'src/models/supplier.model';
import { UserModel } from 'src/models/user.model';

// @services
import { ResourcesService } from './resources.service';
import { SuppliersService } from './suppliers.service';
import { UsersService } from './users.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
class StorageService {
  private usersSubject: BehaviorSubject<UserModel[]> = new BehaviorSubject<UserModel[]>([]);
  public users$: Observable<UserModel[]> = this.usersSubject.asObservable();

  private currentUserSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  public currentUser$: Observable<UserModel> = this.currentUserSubject.asObservable();

  private selectedUserSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  public selectedUser$: Observable<UserModel> = this.selectedUserSubject.asObservable();

  private selectedNoteSubject: BehaviorSubject<NoteModel> = new BehaviorSubject<NoteModel>(null);
  public selectedNote$: Observable<NoteModel> = this.selectedNoteSubject.asObservable();

  private resourcesSubject: BehaviorSubject<ResourceModel[]> = new BehaviorSubject<ResourceModel[]>([]);
  public resources$: Observable<ResourceModel[]> = this.resourcesSubject.asObservable();

  private selectedResourceSubject: BehaviorSubject<ResourceModel> = new BehaviorSubject<ResourceModel>(null);
  public selectedResource$: Observable<ResourceModel> = this.selectedResourceSubject.asObservable();

  private suppliersSubject: BehaviorSubject<SupplierModel[]> = new BehaviorSubject<SupplierModel[]>([]);
  public suppliers$: Observable<SupplierModel[]> = this.suppliersSubject.asObservable();

  private selectedSupplierSubject: BehaviorSubject<SupplierModel> = new BehaviorSubject<SupplierModel>(null);
  public selectedSupplier$: Observable<SupplierModel> = this.selectedSupplierSubject.asObservable();

  constructor(
    private users: UsersService,
    private resources: ResourcesService,
    private suppliers: SuppliersService,
    private utils: UtilitiesService
  ) {
    this.getUsers();
    this.getResources();
    this.getSuppliers();
  }

  public getUsers(): void {
    this.users.get().subscribe({
      next: (res: any) => {
        const users: UserModel[] = [];
        for (const key of Object.keys(res)) {

          const favoritesArray: string[] = [];
          const notesArray: NoteModel[] = [];

          if (res[key].hasOwnProperty('favorites')) {
            const favorites = res[key].favorites;
            for (const f of Object.keys(favorites)) {
              favoritesArray.push(favorites[f]);
            }
          }

          if (res[key].hasOwnProperty('notes')) {
            const notes = res[key].notes;
            for (const n of Object.keys(notes)) {
              notesArray.push(new NoteModel(notes[n]));
            }
          }

          const user = new UserModel({
            ...res[key],
            favorites: favoritesArray,
            notes: notesArray,
            id: key
          });

          if (users.includes(user) === false) {
            users.push(user);
          }
        }
        this.usersSubject.next(users);
      },
      error: (err: HttpErrorResponse) => {
       console.log(err);
      }
    });
  }

  public setCurrentUser(user: UserModel): void {
    this.currentUserSubject.next(user);
  }

  public setSelectedUser(user: UserModel): void {
    this.selectedUserSubject.next(user);
  }

  public setSelectedNote(note: NoteModel): void {
    console.log(note);
    this.selectedNoteSubject.next(note);
  }

  public getResources(): void {
    this.resources.get().subscribe({
      next: (res: any) => {
        this.resources.getLearning().subscribe({
          next: (learningRes: any) => {
            const resources: ResourceModel[] = [];
            for (const r of Object.keys(res)) {
              resources.push(new ResourceModel(ResourceTypes.TOOLS, { ...res[r], id: r }));
            }
            for (const l of Object.keys(learningRes)) {
              resources.push(new ResourceModel(ResourceTypes.LEARNING, { ...learningRes[l], id: l }));
            }
            this.resourcesSubject.next(resources);
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
          }
        });
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    });
  }

  public setSelectedResource(resource: ResourceModel): void {
    this.selectedResourceSubject.next(resource);
  }

  public getSuppliers(): void {
    this.suppliers.get().subscribe({
      next: (res: any) => {
        const suppliersArray: SupplierModel[] = [];
        for (const s of Object.keys(res)) {
          suppliersArray.push(new SupplierModel(s, res[s]));
        }
        this.suppliersSubject.next(this.utils.sort(suppliersArray, 'name'));
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
      }
    });
  }

  public setSelectedSupplier(supplier: SupplierModel): void {
    this.selectedSupplierSubject.next(supplier);
  }
}

export {
  StorageService
};
