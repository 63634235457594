// @ng
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// @enums
import { CrudFunctions } from 'src/enums/crudFunctions.enum';
import { UserModel } from 'src/models/user.model';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { UsersService } from 'src/app/modules/core/services/users.service';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss']
})
class ModifyUserComponent implements OnInit {
  @Input() public crud: CrudFunctions;

  public crudFunctions: typeof CrudFunctions = CrudFunctions;

  @Output() public closePanel: EventEmitter<any> = new EventEmitter<any>();

  public currentUser: UserModel = new UserModel();
  public selectedUser: UserModel = new UserModel();

  constructor(
    private storage: StorageService,
    private users: UsersService
  ) { }

  ngOnInit() {
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
      },
      error: err => console.log(err)
    });
    if (this.crud === CrudFunctions.UPDATE || this.crud === CrudFunctions.DELETE) {
      this.storage.selectedUser$.subscribe({
        next: (user: UserModel) => {
          this.selectedUser = user;
        },
        error: err => console.log(err)
      });
    }
  }

  public submit(): void {
    switch (this.crud) {
      case CrudFunctions.CREATE:
        this.users.create(this.selectedUser).subscribe({
          next: () => {
            this.storage.getUsers();
            this.storage.setSelectedUser(null);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
        break;
      case CrudFunctions.UPDATE:
        this.users.update(this.selectedUser).subscribe({
          next: () => {
            this.storage.getUsers();
            this.storage.setSelectedUser(null);
            this.closePanel.emit();
          },
          error: err => console.log(err)
        });
        break;
    }
  }

  public confirmDelete(): void {
    this.users.delete(this.selectedUser).subscribe({
      next: () => {
        this.storage.getUsers();
        this.storage.setSelectedUser(null);
        this.closePanel.emit();
      },
      error: err => console.log(err)
    });
  }

  public cancelDelete(): void {
    this.closePanel.emit();
  }
}

export {
  ModifyUserComponent
};
