// @ng
import { Injectable } from '@angular/core';

// @rxjs
import { BehaviorSubject, Observable } from'rxjs';

// @enums
import { Themes } from '../enums/themes.enum';

// @logging
import { LogEntryTypes, LogEntry, LoggerService } from '../namespaces/logger.namespace';

@Injectable({
  providedIn: 'root'
})
export class ThemeProviderService {
  private context: string;

  private themeSubject: BehaviorSubject<Themes> = new BehaviorSubject<Themes>(null);
  public theme$: Observable<Themes> = this.themeSubject.asObservable();

  constructor(private logger: LoggerService) {
    this.context = this.constructor.name;
    this.themeSubject.next(Themes.LIGHT);
    this.logger.record({
      type: LogEntryTypes.TEXT,
      text: 'Initialized',
      context: this.context
    });
  }

  toggle(): void {
    let t: Themes;
    if(this.themeSubject.value === Themes.LIGHT) {
      t = Themes.DARK;
    } else {
      t = Themes.LIGHT;
    }
    this.themeSubject.next(t);
    this.logger.record(new LogEntry({
      type: LogEntryTypes.TEXT,
      text: `Theme Toggled to : ${t}`,
      context: this.context
    }));
  }
}
