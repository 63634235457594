// @ng
import { Component, OnInit } from '@angular/core';


// @enums
import { Views } from 'src/enums/views.enum';

// @services
import { RouterService } from 'src/app/modules/core/services/router.service';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { UserModel } from 'src/models/user.model';

// @theming
import { Themes, ThemeProviderService } from 'src/app/modules/tds/namespaces/theme.namespace';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
class HeaderComponent implements OnInit {
  public currentUser: UserModel = new UserModel();
  public views: typeof Views = Views;
  public themes: typeof Themes = Themes;
  public theme: Themes;

  constructor(
    private storage: StorageService,
    public router: RouterService,
    public themeProvider: ThemeProviderService
  ) { }

  public ngOnInit(): void {
    this.themeProvider.theme$.subscribe({
      next: (theme: Themes) => {
        this.theme = theme;
      }
    });
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
      },
      error: err => console.log(err)
    });
  }

  public navigate(view: Views): void {
    this.router.setView(view);
  }

  public switchThemes(): void {
    this.themeProvider.toggle();
  }
}

export {
  HeaderComponent
};
