// @ng
import { Component, OnInit } from '@angular/core';


// @enums
import { Views } from 'src/enums/views.enum';

// @logging
import { LogEntryTypes, LogEntry, LoggerService } from 'src/app/modules/tds/namespaces/logger.namespace';

// @services
import { RouterService } from 'src/app/modules/core/services/router.service';

@Component({
  selector: 'app-view-router',
  templateUrl: './view-router.component.html'
})
class ViewRouterComponent implements OnInit {
  private context: string;

  public views: typeof Views = Views;

  constructor(
    private logger: LoggerService,
    public router: RouterService
  ) {}

  public ngOnInit(): void {
    this.context = this.constructor.name;
    this.logger.record(new LogEntry({
      type: LogEntryTypes.TEXT,
      text: 'Initialized',
      context: this.context
    }));
  }
}

export {
  ViewRouterComponent
};
