// @ng
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// @components
import { ProfileViewComponent } from './components/profile-view/profile-view.component';

// @modules
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TdsModule } from 'src/app/modules/tds/tds.module';

@NgModule({
  declarations: [
    ProfileViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TdsModule
  ],
  exports: [
    ProfileViewComponent
  ]
})
class ProfileViewModule { }

export {
  ProfileViewModule
};
