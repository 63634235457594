import { ResourceTypes } from 'src/enums/resourceTypes.enum';

interface IResouceModel {
  address: string;
  name: string;
  id?: string;
  description?: string;
  type?: ResourceTypes;
}

class ResourceModel implements IResouceModel {
  public address: string = null;
  public name: string = null;
  public id: string = null;
  public type: ResourceTypes = null;
  public description: string = null;

  constructor(type: ResourceTypes, model?: IResouceModel) {
    this.type = type;
    if (model) {
      this.address = model.address;
      if (model.id) {
        this.id = model.id;
      }
      if (model.description) {
        this.description = model.description;
      }
      this.name = model.name;
    }
  }
}

export {
  IResouceModel,
  ResourceModel
};
