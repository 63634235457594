// @ng
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { UserModel } from 'src/models/user.model';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
class ProfileViewComponent implements OnInit {
  public richText: string = '';
  public currentUser: UserModel = null;
  public options = {};

  constructor(private storage: StorageService) { }

  ngOnInit() {
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
      },
      error: err => console.log(err)
    });
  }
}

export {
  ProfileViewComponent
};
