// @ng
import { Injectable } from '@angular/core';

// @env
import { environment } from 'src/environments/environment';


// @enums
import { Views } from 'src/enums/views.enum';
import { AgencyModes } from 'src/enums/agencyModes.enum';

// @models
import { UserModel } from 'src/models/user.model';

// @services
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterService } from './router.service';

@Injectable({
  providedIn: 'root'
})
class AccountService {
  constructor(
    private storage: StorageService,
    private router: RouterService
  ) { }

  public login(username: string, password: string): boolean {
    this.storage.users$.subscribe({
      next: (users: UserModel[]) => {
        return this.authenticate(users, username, password);
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        return false;
      }
    });
    return false;
  }

  private authenticate(users: UserModel[], username: string, password: string): boolean {
    const user: UserModel = users.find(x => x.username === username);
    if (user) {
      if (password === environment.adminKey.toLowerCase().trim()) {
        this.router.setAgencyMode(AgencyModes.ADMIN);
        this.router.setView(Views.SUPPLIERS);
        this.storage.setCurrentUser(user);
        return true;
      } else if (password === environment.agencyKey.toLowerCase().trim()) {
        this.router.setAgencyMode(AgencyModes.AGENTS);
        this.router.setView(Views.SUPPLIERS);
        this.storage.setCurrentUser(user);
        return true;
      } else {
        alert(`Password ${password} was not found.`);
        return false;
      }
    } else {
      alert(`Username ${username} was not found.`);
      return false;
    }
  }
}

export {
  AccountService
};
