// @ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

// @env
import { environment } from 'src/environments/environment';

// @rxjs
import { Observable } from 'rxjs';


// @models
import { IUserModel, UserModel } from 'src/models/user.model';

@Injectable({
  providedIn: 'root'
})
class UsersService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = environment.apiBase;
  }

  public get(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/users.json`);
  }

  public create(user: UserModel): Observable<IUserModel> {
    return this.http.post<IUserModel>(`${this.baseUrl}/users.json`, user);
  }

  public update(user: UserModel): Observable<IUserModel> {
    return this.http.put<IUserModel>(`${this.baseUrl}/users/${user.id}.json`, user);
  }

  public delete(user: UserModel): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/users/${user.id}.json`);
  }
}

export {
  UsersService
};
