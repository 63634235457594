// @ng
import { Component, OnInit } from '@angular/core';


// @enums
import { Themes } from '../../enums/themes.enum';

// @logging
import { LogEntryTypes, LogEntry, LoggerService } from '../../namespaces/logger.namespace';

// @services
import { ThemeProviderService } from '../../services/theme-provider.service';

@Component({
  selector: 'tds-theme-provider',
  templateUrl: './theme-provider.component.html',
  styleUrls: ['./theme-provider.component.scss']
})
class ThemeProviderComponent implements OnInit {
  private context: string;
  public themes: typeof Themes = Themes;
  public theme: Themes;

  constructor(
    private logger: LoggerService,
    private themeProvider: ThemeProviderService
  ) { }

  ngOnInit() {
    this.context = this.constructor.name;
    this.themeProvider.theme$.subscribe({
      next: (theme: Themes) => {
        this.theme = theme;
      }
    });
    this.logger.record(new LogEntry({
      type: LogEntryTypes.TEXT,
      text: 'Initialized',
      context: this.context
    }));
  }

}

export {
  ThemeProviderComponent
};
