enum Views {
  AGENCY = 'Agency',
  LOGIN = 'Login',
  NOTES = 'Notes',
  PROFILE = 'Profile',
  RESOURCES = 'Resources',
  SUPPLIERS = 'Suppliers'
}

export {
  Views
};
