// @ng
import { Component, OnInit } from '@angular/core';


// @models
import { Notification } from '../../models/notification.model';

// @services
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'msg-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
class ToastComponent implements OnInit {
  public notification: Notification = new Notification();

  constructor(
    private messenger: NotificationsService
  ) { }

  ngOnInit() {
    this.notification = this.messenger.notification;
  }

}

export {
  ToastComponent
};
