// @ng
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent implements OnInit {
  public heading: string;

  ngOnInit() {
    this.heading = 'Agents Of Tons Of Fun Travel';
  }


}
