// @ng
import { Component, OnInit } from '@angular/core';


// @enums
import { AgencyModes } from 'src/enums/agencyModes.enum';
import { AdminFunctions } from 'src/enums/adminFunctions.enum';

// @models
import { SupplierModel } from 'src/models/supplier.model';

// @services
import { RouterService } from 'src/app/modules/core/services/router.service';
import { StorageService } from 'src/app/modules/core/services/storage.service';
import { UsersService } from 'src/app/modules/core/services/users.service';
import { UtilitiesService } from 'src/app/modules/core/services/utilities.service';
import { UserModel } from 'src/models/user.model';

@Component({
  selector: 'app-suppliers-view',
  templateUrl: './suppliers-view.component.html',
  styleUrls: ['./suppliers-view.component.scss']
})
class SuppliersViewComponent implements OnInit {
  public currentUser: UserModel = null;
  public selectedSupplier: SupplierModel = null;
  public suppliers: SupplierModel[] = [];
  public suppliersToRender: SupplierModel[];

  public showFavorites: boolean;
  public agencyModes: typeof AgencyModes = AgencyModes;

  public showAdminPanelOuter: boolean = false;
  public showAdminPanelInner: boolean = false;

  public adminFunctions: typeof AdminFunctions = AdminFunctions;

  public showRecommendations: boolean = false;
  public showField: boolean = false;

  public newRecommendation: string;

  constructor(
    private storage: StorageService,
    private utils: UtilitiesService,
    public router: RouterService,
    private users: UsersService
  ) {}

  ngOnInit() {
    this.showFavorites = false;
    this.storage.suppliers$.subscribe({
      next: (suppliers: SupplierModel[]) => {
        this.suppliers = suppliers;
        this.render(this.suppliers);
      },
      error: (err) => {
        console.log(err);
      }
    });
    this.storage.selectedSupplier$.subscribe({
      next: (supplier: SupplierModel) => {
        this.selectedSupplier = supplier;
      },
      error: err => console.log(err)
    });
    this.storage.currentUser$.subscribe({
      next: (user: UserModel) => {
        this.currentUser = user;
      },
      error: err => console.log(err)
    });
  }

  public selectSupplier(supplier: SupplierModel): void {
    this.storage.setSelectedSupplier(supplier);
  }

  public toggleFavorites(): void {
    if (this.showFavorites) {
      this.render(this.suppliers);
      this.showFavorites = false;
    } else {
      this.render(this.favoriteSuppliers(this.suppliers));
      this.showFavorites = true;
    }
  }

  public isFavorite(supplier: SupplierModel): boolean {
    return this.currentUser.favorites.includes(supplier.id);
  }

  public favoriteSuppliers(suppliers: SupplierModel[]): SupplierModel[] {
    const qualifyingSuppliers: SupplierModel[] = [];
    suppliers.forEach(supplier => {
      if (this.isFavorite(supplier)) {
        qualifyingSuppliers.push(supplier);
      }
    });
    return qualifyingSuppliers;
  }

  public search(term: string) {
    console.log(term);
    const trimmed = term.trim().toLowerCase();

    if (this.utils.isNullOrEmpty(trimmed)) {
      if (!this.showFavorites) {
        this.render(this.suppliers);
        return;
      } else {
        this.render(this.favoriteSuppliers(this.suppliers));
        return;
      }
    }

    const filtered: SupplierModel[] = [];

    this.suppliers.forEach(s => {
      if (s.name.toLowerCase().includes(trimmed)) {
        if (filtered.includes(s) === false) {
          filtered.push(s);
        }
      } else {
        if (s.tags && s.tags.length > 0) {
          s.tags.forEach(t => {
            if (t.toLowerCase().includes(trimmed)) {
              if (filtered.includes(s) === false) {
                filtered.push(s);
              }
            }
          });
        }
      }
    });

    if (!this.showFavorites) {
      this.render(filtered);
    } else {
      this.render(this.favoriteSuppliers(filtered));
    }
  }

  public render(suppliers: SupplierModel[]): void {
    this.suppliersToRender = suppliers;
  }

  goToSite(url: string): void {
    this.router.openInNewTab(url);
  }

  public addFavoriteToCurrentUser(supplier: SupplierModel): void {
    const user = { ...this.currentUser };
    const index = user.favorites.lastIndexOf(supplier.id);
    if (index === -1) {
      user.favorites.push(supplier.id);
    } else {
      user.favorites.splice(index, 1);
    }

    this.users.update(user).subscribe({
      next: res => console.log(res),
      error: err => console.log(err)
    });
  }

  public openAdminPanelOuter(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelOuter = true;

  }

  public openAdminPanelInner(f: AdminFunctions) {
    this.router.setAdminFunction(f);
    this.showAdminPanelInner = true;
  }

  public closeAdminPanelOuter() {
    this.showAdminPanelOuter = false;
  }

  public closeAdminPanelInner() {
    this.showAdminPanelInner = false;
  }
}

export {
  SuppliersViewComponent
};
