import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AccountService } from '../../core/services/account.service';

@Component({
  selector: 'mobile-login-view',
  templateUrl: './mobile-login.component.html'
})
export class MobileLoginComponent implements OnInit {

  constructor(
    private acct: AccountService
  ) { }

  ngOnInit() {
  }

  public login(form: NgForm) {
    const values = form.value;
    const username: string = values.username.toLowerCase().trim();
    this.acct.login(username, values.password);
  }

}
