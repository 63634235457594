// @ng
import { Injectable } from '@angular/core';


// @enums
import { LogEntryTypes } from '../enums/logEntryTypes.enum';

// @models
import { Log } from '../models/log.model';
import { LogEntry } from '../models/logEntry.model';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private context: string;
  private log: Log;

  constructor() {
    this.log = new Log();
    this.context = this.constructor.name;
    this.record(new LogEntry({
      type: LogEntryTypes.TEXT,
      text: 'Initialized',
      context: this.context
    }));
  }

  public record(entry: LogEntry): void {
    this.log.add(entry);
  }

  public print(): void {
    this.log.get().forEach(entry => {
      console.log(entry);
    })
  }

  public publish(): LogEntry[] {
    return this.log.get();
  }
}
