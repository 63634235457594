// @ng
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';


// @enums
import { LogEntryTypes } from '../enums/logEntryTypes.enum';

interface ILogEntry {
  type: LogEntryTypes;
  context: string;
  httpResponse?: HttpResponse<any>;
  httpError?: HttpErrorResponse;
  error?: string;
  text?: string;
  object?: any;
  name?: string
}

class LogEntry implements ILogEntry {
  public type: LogEntryTypes = null;
  public context: string = null;
  public httpResponse?: HttpResponse<any> = null;
  public httpError?: HttpErrorResponse = null;
  public error?: string = null;
  public text?: string = null;
  public object?: any = null;
  public name?: string = null;

  constructor(model: ILogEntry) {
    this.type = model.type;
    this.context = model.context;
    if(model.error) {
      this.error = model.error
    }
    if (model.httpError) {
      this.httpError = model.httpError;
    }
    if (model.httpResponse) {
      this.httpResponse = model.httpResponse;
    }
    if (model.object) {
      this.object = model.object;
      this.name = model.name;
    }
    if (model.text) {
      this.text = model.text;
    }
  }
}

export {
  LogEntry
};
