import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../core/services/storage.service';
import { SupplierModel } from 'src/models/supplier.model';
import { RouterService } from '../../core/services/router.service';

@Component({
  selector: 'mobile-suppliers-view',
  templateUrl: './mobile-suppliers.component.html'
})
export class MobileSuppliersComponent implements OnInit {
  public selectedSupplier: SupplierModel = null;
  public showDetail = false;

  constructor(
    public storage: StorageService,
    public router: RouterService
  ) { }

  public selectSupplier(supplier: SupplierModel) {
    this.selectedSupplier = supplier;
    if (this.selectedSupplier !== null) {
      this.showDetail = true;
    }
  }

  public closeDetail() {
    this.showDetail = false;
  }

  ngOnInit() {
  }

}
